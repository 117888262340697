import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../scss/contact.scss';

const Contact = ({ contactSecRef }) => {
  // Define state variables for each input field
  const [formData, setFormData] = useState({
    contactName: '',
    contactEmail: '',
    contactMobile: '',
    contactMessage: '',
  });

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });
  };

  // const [contactName, setContactName] = useState('');
  // const [contactEmail, setContactEmail] = useState('');
  // const [contactMessage, setContactMessage] = useState('');
  // const [contactMobile, setContactMobile] = useState('');
  const [successMsg, setSuccessMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      // Form submission logic here
      console.log('Form submitted successfully!');
      const templateParams = {
        from_name: formData.contactName,
        from_email: formData.contactEmail,
        mobile: formData.contactMobile,
        message: formData.contactMessage,
      };
      emailjs.send('service_rxk0rua', 'template_3jom5q4', templateParams, '1UVO2ka0Ig9M6vhTq')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSuccessMsg(true)
        // setContactName('');
        // setContactEmail('');
        // setContactMobile('');
        // setContactMessage('');
        setFormData({
          contactName: '',
          contactEmail: '',
          contactMobile: '',
          contactMessage: '',
        })

        setTimeout(() => {
          setSuccessMsg(false);
        }, 8000);

      }, (error) => {
        console.error('FAILED...', error);
        setErrorMsg(true)

        setTimeout(() => {
          setErrorMsg(false);
        }, 12000);

      });
  } else {
      console.log(`Form submission failed
       due to validation errors.`);
  }
   
    
  };

const validateForm = (data) => {
    const errors = {};

    if (!data.contactName.trim()) {
        errors.contactName = 'Name is required';
        console.log("contact name")
    }

    if (!data.contactEmail.trim()) {
        errors.contactEmail = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.contactEmail)) {
        errors.contactEmail = 'Email is invalid';
    }

    if (!data.contactMobile) {
        errors.contactMobile = 'Mobile number is required';
    } else if (data.contactMobile.length < 10) {
        errors.contactMobile = `Invalid mobile number`;
    }

    // if (data.confirmPassword !== data.password) {
    //     errors.confirmPassword = 'Passwords do not match';
    // }

    return errors;
};

  return (
    <div className='contactformSec' ref={contactSecRef}>
      <div className="contactheading">
        <p className='contact-p'>GET IN <span className="me">TOUCH</span></p>
        <h2 className='contact-h2'>Reach out for a new project</h2>
      </div>
      <div className="formdiv">
        <div className="container">
          <div className="row form-row">
            <div className="col-sm-5 contact-info-sec">
              <div className="cinfo-h">
                <h3>Contact Info</h3>
                <p>Please fill out the form on this section to contact with me. Or call</p>
              </div>
              <div className="contact-info-txt">
                <div className="cinfo">
                  <h5>Email Me At</h5>
                  <p>vamshuch@gmail.com <br />
                    vamshuch@outlook.com</p>
                </div>
                <div className="cinfo">
                  <h5>Call Me At</h5>
                  <p>+91 8099990963</p>
                </div>
                <div className="cinfo">
                  <h5>Address</h5>
                  <p>Hyderabad, Telangana, India - 500081</p>
                </div>
                <div className="social-icon-sec">
                  <ul className="social-icons-footer">
                    <li><a href="https://www.linkedin.com/in/vamshu-ch-23b24a215"><i className="fa-brands fa-linkedin"></i></a></li>
                    <li><a href="https://www.facebook.com/vamshuch.vamshuch"><i className="fa-brands fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/vamshu_ch/"><i className="fa-brands fa-instagram"></i></a></li>
                    <li><a href='mailto:vamshuch@gmail.com' target='blank' ><i className="fa-brands fa-google"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-7 form-sec">
              <div className="form-box">
                <form method="POST" onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="form-field required-field">
                      <input
                        name="contactName"
                        type="text"
                        id="contactName"
                        placeholder="Your Name"
                        value={formData.contactName}
                        minLength="2"
                        aria-required="true"
                        className="full-width cform-field"
                        onChange={handleChange}
                      />
                      {errors.contactName &&
                        <span className="error-message">
                            {errors.contactName}
                        </span>
                    }
                    </div>
                    <div className="form-field required-field">
                      <input
                        name="contactEmail"
                        type="email"
                        id="contactEmail"
                        placeholder="Your Email"
                        value={formData.contactEmail}
                        aria-required="true"
                        className="full-width cform-field"
                        onChange={handleChange}
                      />
                      {errors.contactEmail &&
                        <span className="error-message">
                            {errors.contactEmail}
                        </span>
                    }
                    </div>
                    <div className="form-field required-field">
                      <input
                        name="contactMobile"
                        type="tel"
                        id="contactMobile"
                        placeholder="Your Mobile"
                        value={formData.contactMobile}
                        aria-required="true"
                        className="full-width cform-field"
                        onChange={handleChange}
                      />
                      {errors.contactMobile &&
                        <span className="error-message">
                            {errors.contactMobile}
                        </span>
                    }
                    </div>
                    <div className="form-field">
                      <textarea
                        name="contactMessage"
                        id="contactMessage"
                        placeholder="Your Message"
                        rows="6"
                        cols="50"
                        aria-required="true"
                        className="full-width cform-field"
                        value={formData.contactMessage}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    {!successMsg && (

                      <div className="form-field btn-sec">
                        <button name='submit-btn' className='submit-btn' id="submit-btn" type="submit">SUBMIT</button>
                      </div>
                    )}
                  </fieldset>
                  {successMsg && (
                    <h3 className='form-success' ><b>Thank You!</b> Your form submission has been received. I'll be in touch shortly!</h3>
                  )}
                   {errorMsg && (
                    <h3 className='form-error' ><b>Sorry!</b> Your request could not be processed. Please try again later or contact directly via WhatsApp or email.</h3>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
