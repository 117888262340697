import '../src/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import Container from 'react-bootstrap/Container';
import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import BannerSection from './components/BannerSection';
import About from './components/About';
import Profile from './components/Profile';
import Freelance from './components/Freelance';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import WpChat from './components/WpChat';
import WaveFooter from './components/WaveFooter';
import PageNotFound from './components/PageNotFound';
import Forbidden from './components/Forbidden';
import '../src/scss/mobile-resolution.scss';
// eslint-disable-next-line


function App() {
  const profileSecRef = useRef(null);
  const contactSecRef = useRef(null);

  return (
    <Router >
    <div className="App">
      <Routes>

      <Route path="/" element={
            <>
              <BannerSection profileSecRef={profileSecRef} contactSecRef={contactSecRef}/>
              <About />
              <Profile profileSecRef={profileSecRef}/>
              <Freelance contactSecRef={contactSecRef}/>
              <Projects />
              <Skills />
              <Contact contactSecRef={contactSecRef} />
              <WpChat />
              <WaveFooter />
            </>
          } />

      <Route path="/static" element={<Navigate to="/" />} />
      <Route path="/forbidden" element={<Forbidden />} /> 
      <Route path="*" element={<PageNotFound />} />


      </Routes>
    </div>
    </Router>
  );
}

export default App;
