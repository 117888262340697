import React from 'react'
import '../scss/pagenotfound.scss';

const PagenotFound = (props) => {
    document.title = '404 Page Not Found';

        // textDecorationSyle = 'line-through 10px #131417'
   
    const titleStyle = {
        fontSize:'130px',
        padding:'30px 0px 0px 0px',
        marginTop:'80px' ,
        fontWeight:'300',
        textDecoration : 'line-through 10px #1d1e20',
        lineHeight : 1,
        color:'#9200ee'
    }
    
  return (
    <div className={` pt-5 div-404 text-center`}>

        <h1 className="playful h1-404" aria-label="page not found" style={titleStyle}>
         404 
        </h1>
        <h2 style={{ fontWeight:300, textDecoration: 'overline #818486', color:'#fff'}}>Page Not Found</h2>
    </div>
  )
}

export default PagenotFound
